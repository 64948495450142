import {Box, CircularProgress, Flex, Heading, VStack} from "@chakra-ui/react";
import HighlightItem from "./HighlightItem";
import Error from "./Error";
import useSWR from "swr";


function Highlights({username}) {

    const {
        data,
        error,
        isLoading
    } = useSWR("/highlights?id=" + username)
    console.log("hig data", data)
    if (isLoading) return <CircularProgress isIndeterminate color='green.300'/>
    if (error) return <Error/>
    if (!data || data.length === 0 ) return null;
    return (
        <VStack alignItems={"flex-start"}>
            <Heading as='h6' size='xs'>
                Highlights
            </Heading>
            <Box
                mt={4}
                mb={4}
                overflowX="auto"
                width="100%"
                css={{
                    '&::-webkit-scrollbar': {
                        height: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '4px',
                    },
                }}
            >
                <Flex flexWrap="nowrap" pb={2}>
                {data?.map(highlight => (<HighlightItem highlight={highlight.node ?? highlight} key={highlight.node?.id ?? highlight.id}/>))}
                </Flex>
            </Box>
        </VStack>
    )
}

export default Highlights