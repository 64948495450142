import {
    Modal, ModalBody, ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    CircularProgress
} from "@chakra-ui/react";
import Media from "./Media";
import useSWR from "swr";
import Error from "./Error";


function Highlights({highlightId, isOpen, onClose}) {

    const {
        data,
        error,
        isLoading
    } = useSWR(isOpen ? "/highlight?id=" + highlightId : null)

    return (<>
            <Modal blockScrollOnMount={false} scrollBehavior={"outside"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Stories</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {  isLoading? <CircularProgress  isIndeterminate color='green.300' /> :
                           error || !data ?  <Error message={"Error loading highlights"}/>:
                            <div>
                                    {
                                    data.items?.map((media) => (
                                        <Media key={media.id} media={media}/>
                                    ))
                                }
                            </div>
                            }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Highlights